
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-login-page {
  .eva-page {
    display: flex;
    flex-direction: row;
  }
  .eva-login-page__header {
    position: absolute;
    top: (3 * @eva-padding);
    right: (3 * @eva-padding);
    width: auto;
    background-color: transparent;
  }
  .eva-login-page__logo {
    width: auto;
    height: auto;
  }
  .eva-login-page__login {
    width: 180px;
    margin-top: 8px;
  }
  .eva-form {
    width: 350px;
    margin: auto;
    min-height: auto;
  }
}
