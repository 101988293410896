.eva-login-page .eva-page {
  display: flex;
  flex-direction: row;
}
.eva-login-page .eva-login-page__header {
  position: absolute;
  top: 24px;
  right: 24px;
  width: auto;
  background-color: transparent;
}
.eva-login-page .eva-login-page__logo {
  width: auto;
  height: auto;
}
.eva-login-page .eva-login-page__login {
  width: 180px;
  margin-top: 8px;
}
.eva-login-page .eva-form {
  width: 350px;
  margin: auto;
  min-height: auto;
}
